<template>
    <div class="text-left">

        <form class="col-12" @submit.prevent="topUpWallet">
            <BaseLoading style="min-height: 100px;" v-if="paymentForm.busy"></BaseLoading>
            <div v-else class="form-group">
                <label for="inputEmail3" class="col-form-label text-left">Enter Amount</label>
                <div class="">
                    <input  v-model="paymentForm.amount" class="form-control" id="inputEmail3" placeholder="Enter amount" required="required">
                </div>
            </div>
            <button :disabled="paymentForm.busy" class="btn btn-primary mt-2" type="submit">
                <i class="icon-wallet px-2" ></i>

                Topup Wallet
                 <!-- <img width="100" src="../../../assets/global_assets/images/payment.png" alt="Credit and debit card"> -->
            </button>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    data: () => ({
        loading: false
    }),
    computed: {
        ...mapState("payment", ["paymentForm", "payment_vars"]),
        ...mapState("auth", ["user"])
    },
    methods: {
        ...mapActions('payment', ["_getPaymentUrl"]),
        topUpWallet(){
            if (this.paymentForm.amount && this.paymentForm.amount > 0) {
                
                this.loading = true;
                this.paymentForm.reference = this.user.id
                this.paymentForm.call_back = document.location.href
                this.paymentForm.type = "wallet"
                this._getPaymentUrl(this.paymentForm)
                    .then((res) => {
                        document.location.href = res.data.Data.checkout
                    })
                    .catch((err) => {
                        // display err
                        this.$notify({
                            title: "Error",
                            text: err.response.data ? err.response.data.Message : "Error",
                            style: "danger",
                        });
                        console.log(err);
                    })
                    .finally(() => this.loading = false)
            } else {
                this.$notify({
                        title: "Error",
                        text: "Topup amount cannot be 0",
                        style: "danger",
                    });
            }
        }
    }
    
}
</script>