<template>
  <div class="text-left">
    <BaseLoading
      style="min-height: 400px; height: 90vh;"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <div class="t-page-container">

        <BaseAsidebar></BaseAsidebar>
        <div class="t-page-fluidcol">
        <div class="my-2">
        <div class="row">
          <div class="col-sm-8 col-12">
            <div class="list__orders d-flex  flex-column rounded ">
              <div class="card-header">
                <h3 style="font-size: 1.7rem!important; font-weight: 900!important; ">Order stats</h3>
              </div>

              <div class=" p-2 table-responsive">
                <table class="table text-nowrap">
                  <tbody>
                    <tr
                      v-for="(stat, index) in orderStats"
                      :key="`${index}stats`"
                      v-if="stat.no"
                    >
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="mr-3">
                            <a
                              href="#"
                              class="btn rounded-round btn-icon btn-md"
                              :class="`bg-${stat.color}-400`"
                            >
                              <span class="letter-icon">{{
                                stat.name.charAt(0) || ""
                              }}</span>
                            </a>
                          </div>
                          <div>
                            <a
                              href="#"
                              class="
                                text-default
                                font-weight-semibold
                                letter-icon-title
                              "
                              >{{ stat.name || "" }}</a
                            >
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="text-muted font-size-sm"
                          ><div class="text-muted font-size-sm">
                            <router-link
                            :to="{ name: 'OrderListing', params: { status: stat.value } }"
                              class="badge badge-pill"
                              :class="`bg-${stat.color || 'dark'}`"
                              >{{ stat.value || "" }}</router-link
                            >
                          </div></span
                        >
                      </td>
                      <td>
                        <span
                          class="font-weight-bold"
                          style="font-size: 1.4rem"
                          >{{ stat.no || "" }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div class="settings-wrapper list__orders p-3  bg-white  rounded  ">
              <h3 style="font-size: 1.7rem!important; font-weight: 900!important; ">Top Orders </h3>
            </div> -->

            


            <!-- <section  v-if="orders.length > 0"  class="content-main">
            <header class="orders__header d-sm-flex d-none header-without-form">
                <div class="orders__items  ">
                <div class="orders__id orders__label">
                Order id </div>
                <div class="orders__status orders__label">
                Order Details </div>
                <div class="orders__icon"></div>
                </div>
                <div class="orders__topic orders__label mt-sm-0 mt-3 ">Topic</div>
                <div class="orders__deadline mt-0">
                <span class="orders__deadline-link orders__label ">
                    <span class="deadline-order-txt">Deadline</span>
                    <span class="  arrows">
                    </span>
                </span>
                </div>
            </header>
            <header  class="orders__header d-sm-none d-flex header-without-form">
                <h4 style="min-width; 300px !important;" class="order-info-mob-txt"> My orders</h4>
                
            </header>

            <div v-for="(order, index) in orders" :key="index + 'ords'" class="order-list">
            <section class="list__orders order-preview-info not_paid">
            <div class="list__orders-item">
            <div class="list__orders-id d-flex flex-column align-items-center justify-content-center">
              <router-link v-if="order.id" :to="{ name: 'Order', params: { id: order.id } }">{{order.id || 'Total' }} </router-link>
              <span v-else >{{order.id || 'Total' }} </span>

              <router-link v-if="order.id" :to="{ name: 'Order', params: { id: order.id } }" class="item-link btn--outline d-flex align-items-center justify-content-center " title="Details of this order: type of work, subject, etc.">
              <span class="desc-txt">Details</span>

              </router-link>
              <router-link v-if="isEditable" :to="{ name: 'payment', params: { id: order.id } }" class="item-link my-2 btn_pay d-flex align-items-center justify-content-center " title="Details of this order: type of work, subject, etc.">
              <span class="desc-txt">Pay</span>

              </router-link>
            </div>
            <div class="list__orders-status d-flex  flex-column  ">
            <span v-if="order.id" class="d-flex py-2 t-order-status__text text-left "> Cost: <strong class="px-1" >${{order.cost}}</strong> </span>
            <span v-else class="d-flex py-2 t-order-status__text text-left ">  <strong class="px-1" >${{order.cost}}</strong> </span>
            <span v-if="order.subject_area_text " class=" d-flex t-order-status__text text-left">Subject: <strong class="px-1"  >{{order.subject_area_text}}</strong></span>
            <span v-if="order.pages  " class=" py-2 t-order-status__text text-left ">{{order.pages}} page(s)</span>


            </div>
            <div class="list__orders-icons">
            </div>
            </div>
            <div class="list__orders-topic">
            <p v-if="order.id"  class="mob-txt">Topic:</p>
            <div class="topic-order-txt">
            {{order.title}}
            <div class="t-order-list__status-wrap">
            <div v-if="isEditable && order.id  " class="progress-state-text not_paid fs13">
            Your order has not been paid yet. </div>
            <div class="t-order-list__status-bar">
            <div class="t-order-list__status-bar__inner"></div>
            </div>
            </div>
            </div>
            </div>
            <div class="list__orders-deadline">
            <div class="deadline-flex">
            <p v-if="order.id"  class="mob-txt">Deadline:</p>
            <div :class="order.client_deadline && order.client_deadline.past_due ? 'text-danger' : 'text-success'" class="date-order  d-flex align-items-center justify-content-center ">
                
            <span class="px-1" >
            {{order.client_deadline && order.client_deadline.date}} </span>
            </div>
            </div>
            
            </div>
            
            </section>
            </div>
            </section> -->

            <base-share></base-share>


            <!-- share link to social media -->
            <!-- <base-share></base-share> -->
 

          </div>
          <div class="col-sm-4 col-12">
            <div class="settings-wrapper list__orders py-2  bg-white  rounded  ">
              <div class="card-body">
                <div class="d-flex  align-items-center justify-content-between">
                  <h3 style="font-size: 1.7rem!important; font-weight: 600!important; " >Wallet Balance</h3>
                  <button
                  class="btn btn-success"
                  data-toggle="modal"
                  data-target="#walletModal"
                >
                  Topup Wallet
                </button>
                </div>
                <h1 style="font-size: 1.3em" class="font-weight-bold">
                  ${{ wallet_balance || "0.0" }}
                </h1> 
                
              </div>
            </div>
            <!-- <BaseOrderitem  :orders="orders" ></BaseOrderitem> -->

            

          </div>
        </div>
      </div>
        </div>

      </div>
    </div>
    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup></WalletTopup>
    </BaseModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";

export default {
  name: "Dashboard",
  components: {
    WalletTopup,
  },
  computed: {
    ...mapState("dashboard", ["wallet_balance", "orderStats"]),
    ...mapState("orders", ["orders"]),

    ...mapState("auth", ["user"]),
    siteLink() {
      return this.user.referral.link || {};
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions("dashboard", ["_getWalletBalance", "_getOrderStats"]),
    ...mapActions("orders", ["_getOrders", "_navigate"]),

    copyToClipboard(link) {
      document.execCommand("copy");
      alert("Referral link copied to clipboard..");
    },
  },
  

  async mounted() {
    this.loading = true;
    await this._getWalletBalance().catch((err) => console.log(err));
    await this._getOrderStats().catch((err) => console.log(err));
    this._getOrders("unpaid")
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
    this.loading = false;
  },
};
</script>
